import {Autoplay, Navigation, Pagination} from "swiper/modules";
import Swiper from 'swiper';

const swiper = new Swiper('.recent-vacancy-blocks', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Autoplay],
    slidesPerView: "auto",
    spaceBetween: 0,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    autoplay: {
        delay: 4000,
        disableOnInteraction: true,
    },

});
